import Portal from './views/Portal/Portal';
import Dashboard from './views/Dashboard/Dashboard';

import { BrowserRouter as Router, Switch, Route} from "react-router-dom";

import ScrollToTop from "./helpers/ScrollToTop";

const Main = () => {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route path="/dash" component={Dashboard}></Route>
                <Route path="/" component={Portal}/>
            </Switch>
        </Router>
    )
}

export default Main
