
import Home from './Home';
import Policies from './Policies';

import Header from "../../components/Header/Navbar";
import Footer from "../../components/Footer";
import NoMatch from "./Page404";

import {Switch, Route} from "react-router-dom";

import ScrollToTop from "../../helpers/ScrollToTop";

function Portal() {
  return (
      <div className="portal-content">
        <ScrollToTop />
        <Header/>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/policies"><Policies /></Route>
            <Route component={NoMatch}/>
          </Switch>
        <Footer/>
      </div>
  );
}
export default Portal;
