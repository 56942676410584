import './views/styles/App.css';
import './views/styles/Homepage.css';
import TagManager from 'react-gtm-module';
import Main from "./Main"

// import { Helmet } from 'react-helmet';

const tagManagerArgs = {
  gtmId: 'GTM-KQF3SND'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <div className="App">
      <Main/>
    </div>
  );
}
export default App;
