import '../styles/Homepage.css';

import { useState, useEffect } from 'react';
import {HelmetProvider, Helmet} from 'react-helmet-async'

const Home = () => {
    const [isPending, setIspending] = useState(false);
    useEffect(() => {
        console.log("use effect ran");
    });
    return (
        <main className="l-main">
          {isPending && <div>Loading...</div> }
          <section className="hero" id="hero">
            <div className="hero__container bd-grid">
              <div className="hero__wrapper">
                <div className="hero__data hero__item">
                  <h1 className="hero__title">Comece sua carreira do futuro agora!</h1>
                  <p className="hero__description">Se você é <strong>estudante do ensino médio público</strong>, a gente vai te ajudar a dar as primeiras remadas da sua carreira. E melhor, sem pagar nada!</p>
                  <a href="http://bit.ly/kanoa-lista" target="_blank" rel="noreferrer" className="primary-button btn">Vamos remar juntos!</a>
                  <p className="hero__disclaimer">Ou, continue abaixo para saber mais!</p>
                </div>
                <div className="hero__image hero__item">
                  <img src="images/hero-image.svg" alt="Hero"/>     
                </div>
              </div>
            </div>
          </section>
  
          <section className="how" id="how">
            <div className="container bd-grid">
              <h1 className="section-title how-title">
                Como funciona?
              </h1>
              <div className="card-wrapper">
                <div className="card-container">
                  <span className="dot">1</span>
                  <h2 className="card-title">
                    Inscrição
                  </h2>
                  <p>Se inscreva na <a href="http://bit.ly/kanoa-lista" target="_blank" rel="noreferrer" className="inline-link">Lista da Kanoa</a> pra gente começar a se conhecer. E chame mais colegas para remarem junto com a gente! Com mais gente, remamos mais longe!</p>
                </div>
                <div className="card-container">
                  <span className="dot">2</span>
                  <h2 className="card-title">
                    Experiência
                  </h2>
                  <p>
                    Aqui começa a navegação! Você vai passar por várias fases para se conhecer melhor, ganhar habilidades novas e aprender sobre o mercado de trabalho. A cada etapa você acumula pontos que te levam para águas cada vez mais desafiadoras!
                  </p>
                </div>
                <div className="card-container">
                  <span className="dot">3</span>
                  <h2 className="card-title">
                    Oportunidades
                  </h2>
                  <p>
                    As empresas parceiras vão se juntar às nossas águas! Vamos te conectar com as oportunidades profissionais que fazem mais sentido para você!
                  </p>
                </div>
              </div>
              <div className="how-text">
                E você deve estar se perguntando, tudo isso de graça? <u>Sim!</u><br/>
                O que esperamos de você é que dê o seu melhor em cada remada :) Partiu?
              </div>
            </div>
          </section>
  
          <section className="what" id="what">
            <div className="what-container bd-grid">
              <h1 className="what-title section-title">
                Mas o que tem dentro dessa Kanoa aí?
              </h1>
              <div className="card-wrapper">
                <div className="card-container">
                  <span className="dot"><img src="images/talent.svg" alt="Facebook Logo"/></span>
                  <h2 className="card-title">Oportunidades</h2>
                  <p>Que tem muita oportunidade por aí a gente sabe, vamos te conectar com as melhores para você!</p>
                </div>
                <div className="card-container">
                  <span className="dot community"><img src="images/speech-bubble.svg" alt="Facebook Logo"/></span>
                  <h2 className="card-title">Comunidade</h2>
                  <p>Mas como chegar lá? Não se preocupe! Desenharemos o caminho  juntos. E estaremos sempre remando ao seu lado!</p>
                </div>
                <div className="card-container">
                  <span className="dot heart"><img src="images/like.svg" alt="Facebook Logo"/></span>
                  <h2 className="card-title">Apoio</h2>
                  <p>E não seremos só nós ao seu lado nesta remada. Estudantes do Brasil inteiro estarão navegando com você também!</p>
                </div>
                <div className="card-container">
                  <span className="dot"><img src="images/atom.svg" alt="Facebook Logo"/></span>
                  <h2 className="card-title">Tecnologia</h2>
                  <p>Mar calmo nunca fez bom marinheire. Com a ajuda da tecnologia, vamos  a navegar essas águas que podem parecer turbulentas.</p>
                </div>
              </div>
              <div className="what-cta-container">
                <a href="http://bit.ly/kanoa-lista" target="_blank" rel="noreferrer" className="primary-button btn">Vamos remar juntos!</a>
              </div>
            </div>
          </section>
  
          <section className="why" id="why">
            <div className="container bd-grid">
              <h1 className="section-title why-title">
                De onde vem essa Kanoa?
              </h1>
              <div className="wrapper">
                <div className="why-image">
                  <img src="images/why-image.svg" alt="Direction"/>  
                </div>
                <div className="why-content">
                  <strong>Kanoa</strong> na língua havaiana significa “<i>Free One</i>”, ou em tradução literal “Pessoa livre”.<br /><br />
                  <strong>Canoas</strong> também são utilizadas para navegar rios afora, calmos e bravos.<br /><br />
                  Essa Kanoa está sendo construída com muito amor para juntos garantirmos que os jovens do Brasil sejam realmente livres. <br /><br />
                  Livres para <strong>escolher</strong> e <strong>alcançar</strong> seus sonhos.
                </div>
              </div>
            </div>
          </section>
          <section className="contact" id="contact">
            <div className="container bd-grid">
              <h1 className="section-title">
                Se perdeu neste mar de informações?
              </h1>
              <p className="sub-title">
                Manda sua dúvida, sugestão ou comentário para a gente!
              </p>
              <div className="wrapper">
                {/* <form action="mailto:gui.rfranzoni@gmail.com" method=”POST” enctype=”multipart/form-data” name=”EmailForm”>>
                      <label>Sua dúvida/comentário:</label>
                      <input name="user-email" type="text" placeholder="email@example.com">
                      <label>E-mail (opcional):</label>
                      <input name="user-email" type="email" placeholder="email@example.com">
  
                  </form> */}
                <a href="http://bit.ly/kanoa-duvidas" target="_blank" rel="noreferrer" className="primary-button btn contact-button">Fale Conosco!</a>
              </div>
            </div>
          </section>
        </main>
    )
}

export default Home
