import React from 'react'

import {HelmetProvider, Helmet} from 'react-helmet-async';

const Dash_Home = ({handleLogout}) => {
    return (
        <section className="logged">
            <HelmetProvider>
                <Helmet>
                    <title>KanoaDASH - Dashboard da Kanoa</title>
                    <meta name="robots" content="noindex"/>
                    <meta name="robots" content="nofollow"/>
                </Helmet>
            </HelmetProvider>
            <nav>   
                <h2>Bem-vindo</h2>
                <button onClick={handleLogout} className="btn-inline dash-btn">Sair</button>
            </nav>
            
        </section>
    )
}

export default Dash_Home
