import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyAU_rCdl4yLdYFMB2ZPdk0ikRvteYmemuk",
    authDomain: "kanoa-dev.firebaseapp.com",
    projectId: "kanoa-dev",
    storageBucket: "kanoa-dev.appspot.com",
    messagingSenderId: "997545732254",
    appId: "1:997545732254:web:f0d7bac7defd9c2bdbf38f",
    measurementId: "G-32XL9DNH48"
};

const fire = firebase.initializeApp(firebaseConfig);

export default fire;