import { useState } from "react"
import { Link } from "react-router-dom";

const Navbar = (props) => {
    const [click, setClick] = useState(true);

    const handleClick = () => setClick(!click);
    return (
        <header className="l-header">
        <nav className="nav bd-grid">
            <div className="logo__wrapper">
            <Link to="/" className="nav__logo">
                <img src="images/logo_full.svg" alt="Logo Icon"/>               
            </Link>
            </div>
            <div className='nav__toggle' id='nav-toggle' onClick={handleClick}>
                <i className="bx bx-menu" />
            </div>
            <div className={click ? 'nav__menu' : 'nav__menu show'} id="nav-menu">
            <div className="nav__close" id="nav-close" onClick={handleClick}>
                <i className="bx bx-x" />
            </div>
            <ul className="nav__list">
                <li className="nav__item"><a href="/#contact" className="nav__link">Contato</a></li>
                <li className="nav__item"><a href="http://bit.ly/kanoa-lista" target="_blank" rel="noreferrer" className="nav__link nav-cta">Inscreva-se!</a></li>
            </ul>
            </div>
        </nav>
        </header>
    )
}

Navbar.defaultProps = {
    tittle: 'Kanoa - Vem remar com a gente!'
}

export default Navbar
