import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <section className="footer">
        <div className="container bd-grid footer-container">
          <div className="wrapper footer-wrapper">
            <div className="company-info">
              <div className="footer-logo">
                <a href="/">
                  <img src="images/logo_full_white.svg" alt="Logo"/>  
                </a>
              </div>
              <div className="social-network">
                <div className="social-network_title">
                  Siga a gente:
                </div>
                <div className="social-logo">
                  <a href="https://www.facebook.com/br.kanoa" target="_blank" rel="noreferrer"><img src="images/face-icon.svg" alt="Facebook Logo"/></a> 
                </div>
                <div className="social-logo">
                  <a href="https://www.instagram.com/kanoa.br" target="_blank" rel="noreferrer"><img src="images/insta-icon.svg" alt="Instagram Logo"/></a>
                </div>  
                <div className="social-logo">
                  <a href="https://www.linkedin.com/company/kanoabr" target="_blank" rel="noreferrer"><img src="images/linkedin-icon.svg" alt="Linkedin Logo"/></a>
                </div>
              </div>
            </div>
            <div className="footer-links-wrapper">
              <div className="footer-links-card">
                <h2 className="footer-links-title">
                  Kanoa
                </h2>
                <ul>
                  <li><a href="http://bit.ly/kanoa-duvidas" target="_blank" rel="noreferrer">Fale conosco</a></li>
                </ul>
              </div>
             <div className="footer-links-card">
                <h2 className="footer-links-title">
                  Nossos termos
                </h2>
                <ul>
                  <li><Link to="/policies">Termos & Condições</Link></li>
                </ul>                        
              </div>
            </div>
          </div>
          <div className="copyright">
            © 2021 Kanoa. Feito com ♥ para você.
          </div>
        </div>
      </section>
    )
}

export default Footer
