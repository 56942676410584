import React from 'react'

const Page404 = () => {
    return (
        <section className="page-not-found">
            <div className="container bd-grid">
                <h1 className="section-title">
                    Página não encontrada! :(
                </h1>
                <div className="card-wrapper">
                    <div className="card-container">
                    <h2>Infelizmente este link não existe ou está quebrado.<br/>Volte para <a href="/" className="inline-link">página inicial da Kanoa</a>!<br/><br/> Se isto for um bug nos avise em <strong>oi@kanoa.com.br</strong></h2>
                    </div>
                    <div className="card-container">
                    <h2 className="card-title">
                        <img src="images/404_1.svg" alt="404"/>
                    </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Page404
