import React from 'react'

import {HelmetProvider, Helmet} from 'react-helmet-async';

const Login = (props) => {
    const { email, setEmail, password, setPassword, handleLogin, handleSignUp, hasAccount, setHasAccount, emailError, passwordError} = props;


    return (
        <section className="login">
            <HelmetProvider>
                <Helmet>
                    <title>KanoaDASH - Entre!</title>
                    <meta name="robots" content="noindex"/>
                    <meta name="robots" content="nofollow"/>
                </Helmet>
            </HelmetProvider>
            <div className="loginContainer">
                <label>E-mail</label>
                <input type="email" autoFocus required value={email} onChange={(e) => setEmail(e.target.value)} id="signup-email"/>
                <p className="errorMsg">{emailError}</p>
                <label>Password</label>
                <input type="password" autoFocus required value={password} onChange={(e) => setPassword(e.target.value)} id="signup-password"/>
                <p className="errorMsg">{passwordError}</p>
                <div className="btnContainer">
                    {hasAccount ? (
                    <>
                    <button onClick={handleLogin} className="dash-btn">Entrar</button>
                    <p>Não tem conta?<span onClick={() => setHasAccount(!hasAccount)}> Cadastre-se!</span></p>
                    </>
                    ) : (
                    <>
                    <button onClick={handleSignUp} className="dash-btn">Cadastrar</button>
                    <p>Já tem conta?<span onClick={() => setHasAccount(!hasAccount)}> Entre!</span></p>
                    </>
                    )}
                </div>
            </div>
        </section>
    )
}

export default Login
