import React, { useState, useEffect} from "react";
// import Header from "../../components/Header/Navbar";
// import Login from "./LoginPage";
import fire from '../../fire'
import Login from './Login';
import Home from './Dash_Home';
import '../styles/dashboard/Dashboard.css';

import Loader from "react-spinners/PulseLoader";

const Dashboard = () => {
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [hasAccount, setHasAccount] = useState(true);
    const [loading, setLoading] = useState(true);

    const clearInputs = () => {
        setEmail('');
        setPassword('');
    }
    
    const clearErrors = () => {
        setEmailError('');
        setPasswordError('');
    }

    const handleLogin = () => {
        clearErrors();
        fire
            .auth()
            .signInWithEmailAndPassword(email,password)
            .catch(err =>{
                switch(err.code){
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setEmailError(err.message);
                        break;
                    case "auth/wrong-password":
                        setPasswordError(err.message);
                        break;
                }
            })
    }
    
    const handleSignup = () => {
        clearErrors();
        fire
            .auth()
            .createUserWithEmailAndPassword(email,password)
            .catch(err =>{
                switch(err.code){
                    case "auth/email-already-in-use":
                    case "auth/invalid-email":
                        setEmailError(err.message);
                        break;
                    case "auth/weak-password":
                        setPasswordError(err.message);
                        break;
                }
            })
    }

    const handleLogout =  () => {
        fire.auth().signOut();
    }
    
    const authListener = () => {
        fire.auth().onAuthStateChanged((user) => {
            if(user) {
                clearInputs();
                setUser(user);
                setLoading(false);
            } else {
                setUser("");
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        authListener();
    },[]);


    return (            
        <div className="dashboard-content">
            { loading 
                ? (
                <div style={{
                    position: "fixed", 
                    top: "50%", 
                    left: "50%", 
                    transform: "translate(-50%, -50%)"
                  }}>
                <Loader color="#ffb800" loading={loading} size={20} /> 
                </div>
                ) : [
                    (user ? (
                        <Home handleLogout={handleLogout}/>
                    ) : (
                        <Login email={email} setEmail={setEmail} 
                        password={password}
                        setPassword={setPassword}
                        handleLogin={handleLogin}
                        handleSignUp={handleSignup}
                        hasAccount={hasAccount}
                        setHasAccount={setHasAccount}
                        emailError={emailError}
                        passwordError={passwordError}
                        />
                    ))
                ]
            }
        </div>
        
    )
}

export default Dashboard


